<template>
    <div class="d-flex flex-column justify-center align-center fill-height pa-5">
        <img :src="require('../assets/images/404.svg')" width="400" />
        <!-- <h1 class="font-weight-regular">404</h1> -->
        <!-- <h3 class="font-weight-regular">NOT FOUND</h3> -->
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>